import { useLocation } from 'react-router-dom'; // Assuming you're using react-router-dom for routing
import { Base64 } from 'js-base64';

//components
import VerifyUser from './authentication/VerifyUser';
import { CardContent, Button, Typography } from '@mui/material';

export default function Auth() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const decodedEmail = Base64.decode(token);

  const isValidEmail = (decodedEmail) => {
    // Regular expression for basic token validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(decodedEmail);
  };

  const handleDisconnect = () => {
    localStorage.removeItem('access_token');
    window.location.reload();
  }

  return (
    <>
      {localStorage.getItem('access_token') !== null ?
        <>
        <CardContent>
        <Typography sx={{ color: 'text.secondary', mt: 3, textAlign: 'center' }}>
          Currently connected, want to reconnect?
        </Typography>
        <Button onClick={handleDisconnect} fullWidth variant='outlined' sx={{ mt: 3 }}>Reconnect</Button>
        </CardContent>
        </>
      :
        <>
        {token && isValidEmail(decodedEmail) && <VerifyUser email={decodedEmail} />}
        </>
      }
    </>
  );
}
